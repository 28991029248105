import { ChainId } from '@layerzerolabs/lz-sdk';
import { Token } from '@layerzerolabs/x-trader-joe-bridge';

const tokens = [
  new Token(ChainId.BSC, '0x5bc4E94CE63c5470515Cbddc903F813580A2A08d', 18, 'ENTER'),
  new Token(ChainId.ETHEREUM, '0x5bc4E94CE63c5470515Cbddc903F813580A2A08d', 18, 'ENTER'),
  new Token(ChainId.AVALANCHE, '0x5bc4E94CE63c5470515Cbddc903F813580A2A08d', 18, 'ENTER'),
];

const proxy = [
  {
    chainId: ChainId.BSC,
    address: '0x5BCEdd074dB2eF46A1a8D62993beAb531E31eF73',
  },
];

export const mainnet = { tokens, proxy };
