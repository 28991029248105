import React from 'react';

import logo from '../assets/enter.png';

const Enter: React.FC = () => {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: "32px",
            paddingTop: "2rem",
            paddingLeft: "1rem",
            color: 'white'
        }}>
            <div>ENTER Token Bridge</div>
            <img
                src={logo}
                alt="Logo"
                style={{
                    marginLeft: '1rem',
                    width: '10vw',
                    maxWidth: '100px',
                    minWidth: '50px'
                }}
            />
        </div>
    );
};

export default Enter;
